/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { store } from 'store';
import moment from 'moment-timezone';
import AdminControllerPermission from 'dto/permissions/admin.permission';
moment.tz.setDefault('Europe/London');

export const getAdvanceData = async () => {
	const data = {
		clientId: store.getState().auth.loginData.clientId,
	};
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.post(
			`${BASE_URL}advance/client-advance`,
			data,
			options
		);
		const progressValue = [
			{ title: 'Loan Provided', desc: 'Lorem Ipsum Dotor' },
			{
				title: `EUR ${res.data[0].totalRepaid} repaid`,
				desc: 'You are here now',
			},
			{
				title: `You owe Valerian EUR ${
					res.data[0].initialBalance + parseFloat(res.data[0].fee)
				}`,
				desc: 'This is a description.',
			},
		];
		return progressValue;
	} catch (err) {
		return err;
	}
};

export const getRepaymentData = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const result = await axios.get(
			`${BASE_URL}jobs/dailyRev/${
				store.getState().auth.loginData.clientId
			}?limit=5`,
			options
		);
		return result;
	} catch (err) {
		return err;
	}
};

export const getAdvanceFullData = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}advance/list/${store.getState().dash.clientData.clientId}`,
			options
		);
		return data;
	} catch (err) {
		return err;
	}
};

export const getAdvanceFullDataDash = async (clientId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const result = await axios.get(
			`${BASE_URL}advance/list/${clientId}`,
			options
		);
		return result;
	} catch (err) {
		return err;
	}
};

export const getAdvanceIdData = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const result = await axios.get(
			`${BASE_URL}advance/rev-calc-list/${
				store.getState().dash.clientData.clientId
			}`,
			options
		);

		return result.data;
	} catch (err) {
		return err;
	}
};

export const getActiveAdvanceFullData = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}advance/list/${store.getState().dash.clientData.clientId}`,
			options
		);
		return data;
	} catch (err) {
		return err;
	}
};

export const getAdvanceHistoricalLogs = async (advanceId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const { data } = await axios.get(
			`${BASE_URL}advance/logs/${advanceId}?limit=100`,
			options
		);
		return data;
	} catch (err) {
		return err;
	}
};

export const getOfferInfo = async (id: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}offer/${id}`, options);
		return result;
	} catch (err) {
		return err;
	}
};

export const getOfferList = async (id: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}offer/list/${id}`, options);
		return result;
	} catch (err) {
		return err;
	}
};

export const getClient = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}client/${store.getState().dash.clientData.clientId}`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const getAllDocuments = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}file/list/${store.getState().dash.clientData.clientId}?limit=5`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const onDocumentPageChange = async (page: number) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}file/list/${
				store.getState().dash.clientData.clientId
			}?limit=5&page=${page}`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const getDocType = async (type: string) => {
	//Option for the axios request
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}file/list/${
				store.getState().dash.clientData.clientId
			}?limit=5&fileType=${type}`,
			options
		);

		return res;
	} catch (err) {
		return err;
	}
};

export const getDocTypePage = async (type: string, page: number) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}file/list/${
				store.getState().dash.clientData.clientId
			}?limit=5&fileType=${type}&page=${page}`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const getClientNotes = async () => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}client/notes?clientId=${
				store.getState().dash.clientData.clientId
			}&limit=5`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const onClientNotesPageChange = async (page: number) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const res = await axios.get(
			`${BASE_URL}client/notes?clientId=${
				store.getState().dash.clientData.clientId
			}&limit=5&page=${page}`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const addNotes = async (data: { description: string }) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const body = {
			...data,
			clientId: store.getState().dash.clientData.clientId,
		};
		const res = await axios.post(`${BASE_URL}client/notes`, body, options);
		message.success('Note added!');
		return res;
	} catch (err) {
		message.error('Could not add the note');
		return err;
	}
};

export const getGraph = async (advanceId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const data = {
			advanceId: advanceId,
		};
		const res = await axios.post(
			`${BASE_URL}revenue/graph/${store.getState().dash.clientData.clientId}`,
			data,
			options
		);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getAdvanceSummary = async (clientId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		const result = await axios.get(
			`${BASE_URL}advance/list/${clientId}`,
			options
		);
		return result;
	} catch (err) {
		return err;
	}
};

export const updateAdvance = async (body: {
	fee: number;
	totalRepaidProjection: number;
	advanceId: string | null;
	note: string;
}) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		axios.patch(`${BASE_URL}advance/${body.advanceId}`, body, options);
		message.success(`Advance ${body.advanceId} updated`);
	} catch (err) {
		message.error('Update Failed');
	}
};

export const updateNote = async (body: {
	noteId: string;
	description: string;
}) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	const reqBody = {
		...body,
		clientId: store.getState().dash.clientData.clientId,
	};
	try {
		axios.patch(`${BASE_URL}client/update-note`, reqBody, options);
		message.success(`Note ${reqBody.noteId} updated`);
	} catch (err) {
		message.error('Note Update Failed');
	}
};

export const deleteNote = async (noteId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	try {
		axios.delete(
			`${BASE_URL}client/delete-note?clientId=${
				store.getState().dash.clientData.clientId
			}&noteId=${noteId}`,
			options
		);

		message.success(`Note has been deleted!`);
	} catch (err) {
		message.error('Failed to delete the note!');
	}
};

export const generateReportData = async (
	values: { fromDate: string; toDate: string } | null,
	field: string,
	clientId?: string
) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	if (values === null) {
		let body;
		clientId
			? (body = {
					reportType: field,
					clientId: clientId,
			  })
			: (body = { reportType: field });
		try {
			const { data } = await axios.post(
				`${BASE_URL}report/generate`,
				body,
				options
			);

			message.success(`Report has been sent to the admin`);
			return data;
		} catch (err) {
			message.error('Report generation failed');
		}
	} else {
		let body;
		clientId
			? (body = {
					reportType: field,
					fromDate: values.fromDate,
					toDate: values.toDate,
					clientId: clientId,
			  })
			: (body = {
					reportType: field,
					fromDate: values.fromDate,
					toDate: values.toDate,
			  });
		try {
			const { data } = await axios.post(
				`${BASE_URL}report/generate`,

				body,
				options
			);

			if (data.status === 405) {
				message.error('There is no data between this timeline');
			}
			if (data.status === 200) {
				message.success(`Report has been sent to the admin`);
			}

			return data;
		} catch (err) {
			message.error('Report generation failed');
		}
	}
};

export const generateJSONReportData = async (
	values: { fromDate: string; toDate: string } | null,
	field: string,
	clientId?: string
) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	if (values === null) {
		let body;
		clientId
			? (body = {
					reportType: field,
					clientId: clientId,
			  })
			: (body = { reportType: field });
		try {
			const { data } = await axios.post(
				`${BASE_URL}report/generate-json`,
				body,
				options
			);

			message.success(`Report has been sent to the admin`);
			return data;
		} catch (err) {
			message.error('Report generation failed');
		}
	} else {
		let body;
		clientId
			? (body = {
					reportType: field,
					fromDate: values.fromDate,
					toDate: values.toDate,
					clientId: clientId,
			  })
			: (body = {
					reportType: field,
					fromDate: values.fromDate,
					toDate: values.toDate,
			  });
		try {
			const { data } = await axios.post(
				`${BASE_URL}report/generate-json`,

				body,
				options
			);

			if (data.status === 405) {
				message.error('There is no data between this timeline');
			}
			if (data.status === 200) {
				message.success(`Report has been sent to the admin`);
			}

			return data;
		} catch (err) {
			message.error('Report generation failed');
		}
	}
};

export const updateAdminAdvance = async (body: any) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};
	const url =
		store.getState().auth?.loggedInUser?.level ===
		AdminControllerPermission.SUPER_ADMIN
			? `${BASE_URL}advance/full/${body.advanceId}`
			: `${BASE_URL}advance/${body.advanceId}`;
	try {
		const res = await axios.patch(url, { ...body }, options);
		message.success(`Advance ${body.advanceId} updated`);
		return res.data;
	} catch (err) {
		message.error('Advance Update Failed');
	}
};

export const recalculateAdvance = async (advanceId: string) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.accessToken}`,
		},
	};

	try {
		const { data } = await axios.post(
			`${BASE_URL}revenue/advance-manual-audit`,
			{ advanceId },
			options
		);
		message.success(data.message, 3);
	} catch (err) {
		message.error(err.response.data.message);
	}
};
